<template>
  <div class="exception-log-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="日志类别：">
        <el-select clearable placeholder="请选择" v-model="searchForm.abnormalType" @change="changeactType">
          <el-option v-for="item in abnormalTypeList" :key="item.actId" :label="item.actName" :value="item.actId" />
        </el-select>
      </el-form-item>
      <el-form-item label="柜机编码：">
        <el-input v-model="searchForm.lockerSearch" placeholder="请输入柜机"></el-input>
      </el-form-item>
      <el-form-item label="格口编码：">
        <el-input v-model="searchForm.cellCode" placeholder="请输入格口"></el-input>
      </el-form-item>
      <el-form-item label="操作时间：">
        <el-date-picker
          v-model="operationTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
        <el-button size="small" @click="exportAll">导出</el-button>
      </el-form-item>
    </el-form>
    <Table
      style="margin-top: 30px"
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
    </Table>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import { getAbnormal } from '@/api/modules/operation'
import { createUuidFn } from '@/utils'
import { array2Sheet } from '@/utils/sheet.js'
const uuid = createUuidFn()
const abnormalTypeList = [
  { actId: 1, actName: '格口' },
  { actId: 2, actName: '柜机信号丢失' },
  { actId: 3, actName: '柜机激活' }
]
export default {
  name: 'exceptionLog',
  components: {
    Table
  },
  data() {
    return {
      searchForm: {
        abnormalType: '',
        lockerSearch: '',
        cellCode: '',
        startTime: '',
        endTime: ''
      },
      columns: [
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '日志类别', prop: 'abnormalTypeText' },
        { label: '描述', prop: 'description' },
        { label: '时间', prop: 'operatorTm' }
      ],
      abnormalTypeList: abnormalTypeList || [],
      operationTime: [],
      tableData: [],
      tableLoading: false,
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  created() {
    this.onSearch(true)
  },
  methods: {
    exportAll() {
      const data = {
        requestTime: new Date().getTime(),
        requestId: uuid('rid'),
        platformId: '001',
        data: {
          ...this.getParams(),
          pageSize: this.pageParams.total,
          pageNum: 1
        }
      }
      getAbnormal(data).then((res) => {
        const { records } = res.data
        console.log(records)
        const sheetData = [this.columns.map((i) => i.label)]
        records.forEach((i) => {
          sheetData.push(
            this.columns.map((j) => {
              return i[j.prop]
            })
          )
        })
        array2Sheet(sheetData, '异常日志', this.columns)
        console.log(sheetData)
      })
    },
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        requestTime: new Date().getTime(),
        requestId: uuid('rid'),
        platformId: '001',
        data: {
          ...this.getParams(flag)
        }
      }
      this.tableLoading = true
      getAbnormal(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records
          this.pageParams = { pageNum, pageSize, total }
          console.log('getAbnormal-res: ', res)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        abnormalType: '',
        lockerSearch: '',
        cellCode: '',
        startTime: '',
        endTime: ''
      }
      this.operationTime = []
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 监听时间变化
    monitorTime(val) {
      const timedata = []
      val &&
        val.forEach((item) => {
          timedata.push(new Date(item).getTime())
        })
      const [start, end] = timedata
      this.searchForm.startTime = start
      this.searchForm.endTime = end
      this.onSearch()
    },
    // 处理select
    changeactType(value) {
      this.searchForm.abnormalType = value
      this.onSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.exception-log-wrap {
  padding: 24px;
}
</style>
