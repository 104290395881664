import dayjs from 'dayjs'

/**
 * 二维数组转Excel
 * @param {Array<Array<String>>} sheetData 二维数组，第一行为表头
 * @param {String} fileName 文件名
 * @param {Array} columns 列参数
 * @param {Object} options 写入选项，check https://www.npmjs.com/package/xlsx#writing-options
 */
export function array2Sheet(sheetData, fileName, columns = [], options = {}) {
  // 动态导入
  import('xlsx').then((XLSX) => {
    // 工作表
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData)
    if (columns.length) {
      // 设定列宽，尝试列参数 'min-width'
      worksheet['!cols'] = columns.map((col) => {
        return { wpx: calculateDefaultMinWidth(col.label, col['min-width']) }
      })
    } else {
      // 设定列宽，列名字符数+1
      worksheet['!cols'] = sheetData[0].map((i) => {
        return { wch: i.length + 1 }
      })
    }
    // Excel对象
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet)
    // 写入文件并下载，文件名_时区_年月日_时分秒
    XLSX.writeFileXLSX(workbook, `${fileName}_${dayjs().format('ZZ_YYYY-MM-DD_HH-mm-ss')}.xlsx`, options)
  })
}
/**
 * 根据列名计算宽度
 * @param {String} columnName
 * @param {Number} width
 * @see TablePanelWrapper.vue 同方法
 */
function calculateDefaultMinWidth(columnName, width = 100) {
  width = Number(width)
  const defaultWidth = columnName.length * 7
  return defaultWidth > width || isNaN(width) ? defaultWidth : width
}
